import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const PortfolioCard = ({ image, tags, description, link, title, hasLink }) => {
  return (
    <motion.div 
      className="relative group overflow-hidden rounded-3xl shadow-lg h-auto bg-slate-900 border-2 border-sky-900"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="relative overflow-hidden h-60">
        {hasLink ? (
          <Link to={link} target="_blank" rel="noopener noreferrer">
            <img
              src={image}
              alt="Project"
              className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
            />
          </Link>
        ) : (
          <img
            src={image}
            alt="Project"
            className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
          />
        )}
      </div>
      <div className="p-4 border-t-2 border-sky-900">
        <h5 className="text-xl font-sans text-sky-600">
          {hasLink ? (
            <Link to={link} target="_blank" rel="noopener noreferrer">
              {title}
            </Link>
          ) : (
            title
          )}
        </h5>
        <div className="flex space-x-2 my-2">
          {tags.map(tag => (
            <span key={tag} className="bg-slate-200 text-xs text-slate-950 rounded-xl px-2 font-semibold font-mono">
              {tag}
            </span>
          ))}
        </div>
        <p className="text-white font-mono">
          {description}
        </p>
      </div>
    </motion.div>
  );
};

export default PortfolioCard;
