import Layout from '../layouts/MainLayout';
import Technologies from '../components/Technologies';
import Timeline from '../components/Timeline';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  return (
    <Layout showProfileCardOnMobile={true}>
      <Helmet>
        <title>Cheovari Ionut | Web Developer</title>
        <meta name="description" content="Cheovari Ionut / Web Developer / Ex-Lawyer / Founder of Destroyed Secure" />
        <meta name="keywords" content="Web Developer / Ex-Lawyer / Founder of Destroyed Secure" />
      </Helmet>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className='text-white text-prety'>
          <h1 className='hidden xl:block text-4xl font-bold'>Welcome</h1>
          <p className='text-lg mt-6 xl:mt-2 font-mono'>
            After graduating from Babeș-Bolyai University of Law and practicing law for three years as an Attorney registered at the Bar of Cluj-Napoca,
            I decided to pivot towards the tech industry, driven by a lifelong interest.
            This transition from law to web development has given me a unique perspective, blending analytical thinking with technical skills.
          </p>
          <p className='text-lg mt-2 font-mono'>
            Now a dedicated web developer, I've been self-educating and engaging in challenging projects to deepen my expertise.
            My venture, Destroyed Secure, combines my legal background with technology, specializing in data destruction and security solutions.
          </p>
          <Technologies />
          <Timeline />
        </div>
      </motion.div>
      
    </Layout>
  );
};

export default Home;
