import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import Loader from './components/Loader';
import Error404 from './pages/404';
import Error500 from './pages/500';


const App = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoading =  () => {
      setLoading(false);
    }

    if (document.readyState === 'complete') {
      // If document is already ready, invoke handler directly.
      handleLoading();
    } else {
      // Otherwise, listen for the load event.
      window.addEventListener('load', handleLoading);
    }

    return () => {
      window.removeEventListener('load', handleLoading);
    };
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/500" element={<Error500 />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    
    </Router>
  );
};

export default App;
