import React from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';

const MobileNavbar = () => {
    return (
        <nav className="fixed bottom-0 left-0 right-0 bg-slate-950 text-white flex justify-around items-center py-3 border-t border-gray-700 z-50">
            <NavLink
                to="/"
                className={({ isActive }) =>
                    isActive
                        ? 'flex flex-col items-center text-sky-600'
                        : 'flex flex-col items-center text-white'
                }
            >
                <HomeIcon />
                <span className="text-xs">Home</span>
            </NavLink>
            <NavLink
                to="/portfolio"
                className={({ isActive }) =>
                    isActive
                        ? 'flex flex-col items-center text-sky-600'
                        : 'flex flex-col items-center text-white'
                }
            >
                <WorkIcon />
                <span className="text-xs">Portfolio</span>
            </NavLink>
            <NavLink
                to="/contact"
                className={({ isActive }) =>
                    isActive
                        ? 'flex flex-col items-center text-sky-600'
                        : 'flex flex-col items-center text-white'
                }
            >
                <EmailIcon />
                <span className="text-xs">Contact</span>
            </NavLink>
        </nav>
    );
};

export default MobileNavbar;
