const Tooltip = ({ text, children }) => {
    return (
      <div className="relative flex flex-col items-center group">
        {children}
        <div className="absolute top-full mb-2 hidden flex-col items-center group-hover:flex">
          <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg rounded-md font-mono">{text}</span>
          <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
        </div>
      </div>
    );
  };
  
  export default Tooltip;