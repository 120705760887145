import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../logo.svg'

const Navbar = () => {
  return (
    <nav className="sticky top-0 bg-slate-950  z-50 p-4 border-b-2 border-sky-600 ">
      <div className="container mx-auto px-5 flex justify-between items-center">
        <div className="logo">
          <NavLink to="/">
            <img src={logo} alt="Logo" className="h-12" />
          </NavLink>
        </div>
        <ul className="flex space-x-6 text-2xl gap-10 ">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? 'text-sky-600'
                  : 'text-white hover:text-sky-700 transition-colors duration-300'
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/portfolio"
              className={({ isActive }) =>
                isActive
                  ? 'text-sky-600'
                  : 'text-white hover:text-sky-600 transition-colors duration-300'
              }
            >
              Portfolio
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive
                  ? 'text-sky-600'
                  : 'text-white hover:text-sky-700 transition-colors duration-300'
              }
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
