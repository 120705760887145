import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, timelineItemClasses } from '@mui/lab';
import { Typography, Paper, Link } from '@mui/material';
import { FaBalanceScaleRight, FaGavel, FaUniversity, FaBuilding, FaLaptopCode } from 'react-icons/fa';

const TimelineComponent = () => {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color='primary'>
            <FaLaptopCode size={20} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} sx={{ p: 4, backgroundColor: 'rgb(15, 23, 42)', color: 'white' }}>
            <Typography variant="h6" component="h1" sx={{ fontWeight: 'bold' }}>
              Web Developer
            </Typography>
            <Typography variant='caption' display="block" sx={{ color: 'white' }}> Jun 2023 - Present</Typography>
            <Typography>
              Currently working as a collaborator with <Link underline='none' color="success" href="https://uptrust.eu" target="_blank">Uptrust Cybersec</Link>, a partner of Destroyed Secure.
              My role involves developing secure web applications, improving cybersecurity measures,
              and working on various client projects to enhance their digital security infrastructure.
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color='primary'>
            <FaBuilding size={20} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} sx={{ p: 4, backgroundColor: 'rgb(15, 23, 42)', color: 'white' }}>
            <Typography variant="h6" component="h1" sx={{ fontWeight: 'bold' }}>
              Founded Destroyed Secure
            </Typography>
            <Typography variant='caption' display="block" sx={{ color: 'white' }}> Jun 2023 </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color='primary'>
            <FaBalanceScaleRight />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} sx={{ p: 4, backgroundColor: 'rgb(15, 23, 42)', color: 'white' }}>
            <Typography variant="h6" component="h1" sx={{ fontWeight: 'bold' }}>
              Attorney at Law
            </Typography>
            <Typography variant='caption' display="block" sx={{ color: 'white' }}> Dec 2022 - Jun 2023</Typography>
            <Typography>
              Drafted legal documents, provided legal consultancy, and facilitated negotiations.
              I also began self-learning programming. I completed the CS50 course from Harvard, followed by learning Python.
              I built small projects and acquired foundational programming skills.
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color='primary'>
            <FaGavel />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} sx={{ p: 4, backgroundColor: 'rgb(15, 23, 42)', color: 'white' }}>
            <Typography variant="h6" component="h1" sx={{ fontWeight: 'bold' }}>
              Junior Lawyer
            </Typography>
            <Typography variant='caption' display="block" sx={{ color: 'white' }}> Sept 2020 - Dec 2022</Typography>
            <Typography>
              I started my journey as a Junior Lawyer at SCA Laura Elena Chiorean & Asociații. While learning some of the
              basics practices of law, i decided i need better practicioneres so i moved to Benedeck where i finished my period as a Junior Lawyer.
              During my tenure as a junior lawyer at Benedeck, I was privileged to immerse myself in the intricate world of business law, spanning areas such as tax law, contract drafting, privacy, and administrative/labor law.
              This esteemed firm, recognized for its impeccable legal consultancy for clients in the IT, real estate, production, and transportation sectors, provided me with a comprehensive foundation.
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <FaUniversity />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} sx={{ p: 4, backgroundColor: 'rgb(15, 23, 42)', color: 'white' }}>
            <Typography variant="h6" component="h1" sx={{ fontWeight: 'bold' }}>
              University of Law
            </Typography>
            <Typography variant='caption' display="block" sx={{ color: 'white' }}>2015 - 2019</Typography>
            <Typography>Babeș Bolyai University, Cluj-Napoca</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export default TimelineComponent;
