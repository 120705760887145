import { IconButton, Link } from '@mui/material';
import { LinkedIn, GitHub, Instagram, Email } from '@mui/icons-material';
import avatarImage from '../assets/images/ionut.webp';
import { motion } from 'framer-motion';

const ProfileCard = () => {

  const downloadCV = () => {
    const link = document.createElement('a');
    link.href = '/CV.pdf';
    link.download = 'CV.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <div className="bg-slate-950  p-10 border-2 border-sky-600 rounded-3xl shadow-md shadow-cyan-600 text-center text-xl text-white opacity-90">
      <h2 className="text-white text-2xl font-semibold mb-4 hover:text-sky-600">Cheovari Ionut</h2>
      <img
        src={avatarImage}
        alt="Cheovari Ionut"
        className="w-full max-w-xs mx-auto mb-4 rounded-lg"
      />
      <p className="text-white">Web Developer</p>
      <p className="text-white">Ex-<Link underline='none' color="rgb(237, 18, 64)" href="https://www.baroul-cluj.ro/tabloul-avocatilor/avocati-suspendati/?wpv_view_count=9650&wpv_post_search=cheovari+ionut&wpv_filter_submit=Cautare" target="_blank" className='hover:text-rose-700'>Lawyer</Link></p>
      <p className="text-white">
        Founder of <Link underline='none' color="rgb(237, 18, 64)" href="https://destroyedsecure.ro" target="_blank" className='hover:text-rose-700'>Destroyed Secure</Link>
      </p>
      <p className="text-white">Based in: Cluj-Napoca, Romania</p>
      <div className="flex justify-center space-x-4 mb-4">
        <IconButton aria-label="LinkedIn" color="primary" component="a" href="https://www.linkedin.com/in/ionut-horia-cheovari-578064291/" target="_blank" className="hover:text-sky-500 transition-colors duration-300">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <LinkedIn />
          </motion.div>
        </IconButton>
        <IconButton aria-label="GitHub" color="primary" component="a" href="https://github.com/CheovariIonutHRS" target="_blank" className="hover:text-sky-500 transition-colors duration-300">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <GitHub />
          </motion.div>
        </IconButton>
        <IconButton aria-label="Instagram" color="primary" component="a" href="https://www.instagram.com/cheovariionut" target="_blank" className="hover:text-sky-500 transition-colors duration-300">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <Instagram />
          </motion.div>
        </IconButton>
        <IconButton aria-label="Email" color="primary" component="a" href="mailto:cheovariionut@yahoo.com" target="_blank" className="hover:text-sky-500 transition-colors duration-300">
          <motion.div
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8 }}
          >
            <Email />
          </motion.div>
        </IconButton>
      </div>
      
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="bg-sky-600 text-white px-6 py-2 rounded-full transition duration-300"
        onClick={downloadCV}
      >
        
        Download CV!
        
      </motion.button>
    </div>
  );
};

export default ProfileCard;
