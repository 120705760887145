import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import { TextField, Container, Typography, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { motion } from 'framer-motion';

const validationSchema = yup.object({
  name: yup.string('Enter your name').required('Name is required'),
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  message: yup.string('Enter your message').required('Message is required'),
  recaptcha: yup.string().required('Please verify that you are not a robot'),
});

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const form = useRef();
  const recaptchaRef = useRef();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
      recaptcha: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then((result) => {
        setLoading(false);
        toast.success('Message sent successfully!', {
          autoClose: 5000,
          closeButton: true,
        });
        resetForm();
      }, (error) => {
        setLoading(false);
        toast.error(`An error occurred: ${error.text}`, {
          autoClose: 5000,
          closeButton: true,
        });
      });
    },
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: '#00bcd4',
      },
      text: {
        primary: '#ffffff',
      },
    },
    typography: {
      fontFamily: 'Permanent Marker, sans-serif',
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-input': {
              color: '#ffffff',
              fontFamily: 'monospace',  // Set input font to monospace
            },
            '& .MuiInputLabel-root': {
              color: '#ffffff',
              fontFamily: 'monospace',  // Set label font to monospace
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#00bcd4',
              },
              '&:hover fieldset': {
                borderColor: '#0097a7',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#00bcd4',
              },
            },
          },
        },
      },
    },
  });

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
        <ThemeProvider theme={theme}>
      <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
        <Typography variant="h4" component="h1" gutterBottom className="text-white font-sans">
          Get in touch
        </Typography>
        <form ref={form} onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            margin="normal"
          />
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
          />
          <TextField
            fullWidth
            id="message"
            name="message"
            label="Message"
            multiline
            rows={4}
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            margin="normal"
          />
          <Box mt={2}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={(value) => formik.setFieldValue('recaptcha', value)}
            />
            {formik.touched.recaptcha && formik.errors.recaptcha && (
              <Typography color="error">{formik.errors.recaptcha}</Typography>
            )}
          </Box>
          <Box mt={2}>
            <button
              type="submit"
              className="bg-sky-600 text-white px-6 py-2 rounded-full hover:bg-sky-800 transition duration-300 font-sans w-full"
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Send Message'}
            </button>
          </Box>
        </form>
      </Container>
    </ThemeProvider>
    </motion.div>
    
  );
};

export default ContactForm;
