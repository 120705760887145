const Footer = ({ isMobile }) => {
  const year = new Date().getFullYear(); // This will automatically update the year

  return (
    <footer className={`text-white ${isMobile ? 'py-20' : 'p-5'} shadow-md text-center`}>
      © {year} All rights reserved | Cheovari Ionut
    </footer>
  );
};

export default Footer;