import Layout from "../layouts/MainLayout";
import ContactForm from "../components/ContactForm";


const Contact = () => {
    return (
      <Layout showProfileCardOnMobile={false}>
        <ContactForm />
      </Layout>
    );
  };

export default Contact;